import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const Error = () => {
  const router = useRouter();
  const goBack = () => {
    router.back();
  };

  // Track 404 pageloads
  // Used to trigger elevated 404 levels
  useEffect(() => {
    if (!router.pathname) return;
    captureException('404 Page Load', { extra: { url: router.pathname } });
  }, [router.pathname]);

  return (
    <div className="grid place-items-center gap-10 text-white py-20">
      <div className="uppercase font-normal font-adieu text-lg leading-[1.4] tracking-[0.15em] 3xl:text-[22px] 4xl:text-[28px]">
        Whoops, nothing here.
      </div>
      <p>
        If the problem persists, please contact us.{' '}
        <a className="text-orange-400 cursor-pointer" onClick={() => goBack()}>
          Go back
        </a>
        .
      </p>
    </div>
  );
};

export default Error;
